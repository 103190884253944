import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-secondary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "400px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/42aad1b8a0684e6f6b096973e2901059/066f9/f1a23fdb0a74178acd62734e2948bfdd8a67ce02.jpg",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAADBAL/2gAMAwEAAhADEAAAAevNNnTZNorcg0vpA2//xAAZEAEBAQADAAAAAAAAAAAAAAACAwEAESP/2gAIAQEAAQUCpVNvHPJVxjepWpQ4JS86jEZzOrn/xAAaEQEAAwADAAAAAAAAAAAAAAABAAIREBIh/9oACAEDAQE/AdTCp5HpstZLYcf/xAAaEQABBQEAAAAAAAAAAAAAAAAAAQIQESFR/9oACAECAQE/Aa6aNTI//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERIRICEDFBgf/aAAgBAQAGPwLDQZL0ll8PsdzIi9//xAAaEAEBAAMBAQAAAAAAAAAAAAABABEhMUFR/9oACAEBAAE/IaoOXR4YSgMvjM7Icl0dONyZHLcDkGDBf//aAAwDAQACAAMAAAAQmx8A/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQEUH/2gAIAQMBAT8QGaCh0xhREHlf/8QAGREBAAMBAQAAAAAAAAAAAAAAAQARIRAx/9oACAECAQE/EKtvqGdgN03n/8QAHBABAQACAwEBAAAAAAAAAAAAAREAUSExYXGR/9oACAEBAAE/EGd4M4ZQ7V1l0nIk4M69hffc41kmg6cJ4IxbaSfMM7rQ06ysJH7hP0GAQQOjP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "MYP_ENG.jpg",
          "title": "MYP_ENG.jpg",
          "src": "/static/42aad1b8a0684e6f6b096973e2901059/066f9/f1a23fdb0a74178acd62734e2948bfdd8a67ce02.jpg",
          "srcSet": ["/static/42aad1b8a0684e6f6b096973e2901059/f93b5/f1a23fdb0a74178acd62734e2948bfdd8a67ce02.jpg 300w", "/static/42aad1b8a0684e6f6b096973e2901059/066f9/f1a23fdb0a74178acd62734e2948bfdd8a67ce02.jpg 400w"],
          "sizes": "(max-width: 400px) 100vw, 400px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h2 {...{
      "id": "programme-description",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#programme-description",
        "aria-label": "programme description permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Programme Description`}</h2>
    <h3 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h3>
    <p>{`The International Baccalaureate Middle Years Programme (MYP) is a five-year programme for students aged 11-16 (Grades 6-10). It was started by international school teachers in response to a perceived need for a truly international curriculum. Currently, 1,209 schools in more than 95 countries worldwide are authorised to run the MYP, and many more are in the process of gaining authorisation.`}</p>
    <p>{`The MYP helps students develop the conceptual understandings, knowledge, attitudes and skills they need to participate actively and responsibly in a changing and increasingly interrelated world. It teaches students to become independent learners who can recognise relationships between school subjects and the world outside; who can adapt to new situations; and who can combine relevant knowledge with practical and social intelligence to solve problems alone and / or in groups.`}</p>
    <p>{`In each of the five years of the MYP, students study eight subjects: Language and Literature taught at native language level, Language Acquisition for students learning a second or additional language, Individuals & Societies, Science, Mathematics, Arts (Art, Music, or Drama), Design, and Physical & Health Education.`}</p>
    <p>{`MYP Subject Guides identify concepts to be delivered. These concepts have an essential place in the structure of knowledge. They require students to demonstrate levels of thinking that reach beyond facts or topics. Concepts are used to formulate the understandings that students should retain in the future; they become principles and generalisations that students can use to understand the world and to succeed in further study and in life beyond school. The exploration and re-exploration of concepts lead students towards the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A deeper understanding of the subject group`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`appreciation of ideas that transcend disciplinary boundaries`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`engagement with complex ideas, including the ability to transfer and apply ideas and skills to new situations From: `}<em parentName="p">{`MYP: From Principles into Practice`}</em>{` (2014)`}</p>
      </li>
    </ul>
    <p>{`Teachers organise their curriculum through Global Contexts (Identities & Relationships, Orientation in Space and Time, Personal & Cultural Expression, Scientific & Technical Innovation, Globalisation & Sustainability, and Fairness & Development), so that students learn how these contexts help to bring meaning and a ‘real world context’ to each subject area.`}</p>
    <p>{`The IB gives the school the flexibility to organise the curriculum according to its own requirements and context. However, all MYP schools must structure their curriculum to allow their students to achieve the aims and objectives of each subject group in terms of the final achievement at the end of the programme.`}</p>
    <p>{`Many of the programme elements and the underlying philosophy found in the MYP are consistent with those in both the IB PYP and the IB DP and builds upon the former to support student success in the latter.`}</p>
    <h2 {...{
      "id": "periods-of-instruction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#periods-of-instruction",
        "aria-label": "periods of instruction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Periods of Instruction`}</h2>
    <p>{`Grade 6 10 Curriculum/ Number Of 40 Minute Periods Of Instruction In A 6 Day Cycle`}</p>
    <table>
    <colgroup>
        <col style={{
          "width": "40%",
          "paddingRight": "40px"
        }} />
        <col align="right" style={{
          "width": "12%",
          "textAlign": "right"
        }} />
        <col align="right" style={{
          "width": "12%",
          "textAlign": "right"
        }} />
        <col align="right" style={{
          "width": "12%",
          "textAlign": "right"
        }} />
        <col align="right" style={{
          "width": "12%",
          "textAlign": "right"
        }} />
        <col align="right" style={{
          "width": "12%",
          "textAlign": "right"
        }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><p>Subject Area: PSI Offerings</p></th>
        <th><p>Grade 6</p></th>
        <th><p>Grade 7</p></th>
        <th><p>Grade 8</p></th>
        <th><p>Grade 9</p></th>
        <th><p>Grade 10</p></th>
    </tr>
    </thead>
    <tr className="odd">
        <td><p>Arts: Visual Arts, Music and Drama</p></td>
        <td><p>4</p></td>
        <td><p>4</p></td>
        <td><p>4</p></td>
        <td><p>4</p></td>
        <td><p>4</p></td>
    </tr>
    <tr className="header">
        <td><p>Individual &amp; Societies</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>6</p></td>
        <td><p>6</p></td>
    </tr>
    <tr className="odd">
        <td><p>Language &amp; Literature: English, Russian, French, Ukrainian</p></td>
        <td><p>6*</p></td>
        <td><p>6*</p></td>
        <td><p>6*</p></td>
        <td><p>6</p></td>
        <td><p>6</p></td>
    </tr>
    <tr className="header">
        <td><p>Language Acquisition: English, Russian, French,Spanish</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
    </tr>
    <tr className="odd">
        <td><p>Mathematics,Extended Mathematics</p></td>
        <td><p>5</p></td>
        <td><p>6</p></td>
        <td><p>6</p></td>
        <td><p>6</p></td>
        <td><p>6</p></td>
    </tr>
    <tr className="header">
        <td><p>Physical &amp; Health Education</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
    </tr>
    <tr className="odd">
        <td><p>Science:</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>6</p></td>
        <td><p>6</p></td>
    </tr>
    <tr className="header">
        <td><p>Design:</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>5</p></td>
        <td><p>4</p></td>
        <td><p>4</p></td>
    </tr>
    <tr className="odd">
        <td><p>Library:</p></td>
        <td><p>1</p></td>
        <td><p>1</p></td>
        <td><p>1</p></td>
        <td><p>n/a</p></td>
        <td><p>n/a</p></td>
    </tr>
    </table>
    <p>{`*`}{`Additional Notes (see below)`}</p>
    <p><strong parentName="p">{`Arts`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In Grades 6, 7 and 8, students currently rotate among Drama, Visual Arts, Music, and Design. (Students take two courses each semester).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In Grade 9, students select one subject from Visual Art, Music, or Drama for their final two years of the programme. (Students cannot change options between Grade 9 and Grade 10.)`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Language and Literature`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Not all languages are available each year in both Language & Literature and Language Acquisition. Languages are offered based on class size and an assessment of student need.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In addition to English (which is always available), the school offers Language & Literature Ukrainian, French and Russian at MYP level, provided that there is sufficient enrolment in these courses.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Language Acquisition`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`French, Spanish and Russian are offered from Phase 1 through Phase 5 based on teacher recommendation. Not all phases are available at all grade levels.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`English language learners take English Language Acquisition. Additionally, some students in Grades 6-10 will take English as an Additional Language (EAL) instead of a second language.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Mathematics`}</strong></p>
    <ul>
      <li parentName="ul">{`Students in Grade 6 - 8 take Mathematics at the same level. In Grades 9 and 10, students are offered two levels of the subject: Mathematics and Extended Mathematics. Students are placed in their Maths groups based on the results of the placement test, work ethic, and teacher’s recommendation.`}</li>
    </ul>
    <h2 {...{
      "id": "homerooms",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#homerooms",
        "aria-label": "homerooms permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Homerooms`}</h2>
    <p>{`Students in Grade 6 have two 40-minute homeroom periods that help them focus on both social-emotional issues and on developing the organisational strategies that they need to be successful in Secondary School. Students in Grades 7 - 10 have one 40-minute period that focuses on Personal and Social Education, and are designed to be an extension of the topics addressed in the Advisory programme. During these specific sessions, students learn about important issues such as personal and social development, academic guidance and further study / career planning. The goal of the PSE programme is to relate what happens in the classroom to the outside world and to support the objectives of the academic curriculum.`}</p>
    <p>{`In addition, the Homeroom programme does the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Prepares students for the grade level Week Without Walls and the assembly presentation that follows`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Encourages understanding of the MYP, especially the Learner Profile`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Helps students to complete the Personal Project in Grade 10`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Develops effective study skills related to research, library skills and referencing`}</p>
      </li>
    </ul>
    <p>{`Homeroom teachers:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Explain school rules and their implementation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inform students about all aspects of school life`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Promote student well-being`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Communicate with parents through all available channels`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Promote the attributes of the learner profile`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Foster effective study habits and encourage students to take greater control over their learning`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Monitor students’ social well-being and academic progress and share the results with colleagues throughout the academic year`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "service--action",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#service--action",
        "aria-label": "service  action permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Service & Action`}</h2>
    <p>{`The IB Mission Statement stresses that education extends beyond intellectual achievement. Through their engagement with Service & Action, MYP students should do the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`become more aware of their own strengths and areas for growth`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`undertake challenges that develop new skills`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`discuss, evaluate and plan student-initiated activities and persevere in those actions`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`work collaboratively with others`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`develop international-mindedness through global engagement and intercultural understanding`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`consider the ethical implications of their actions.`}</p>
      </li>
    </ul>
    <p>{`All students from Grade 6 to 10 participate in the Service & Action programme, predominantly through curriculum links. As a result, students develop positive attitudes and a sense of personal and social responsibility – qualities that should prove of value to them in their lives as a whole.`}</p>
    <h2 {...{
      "id": "personal-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#personal-project",
        "aria-label": "personal project permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Personal Project`}</h2>
    <p>{`All Grade 10 students are required to complete a Personal Project, which may take the form of a research essay, an artistic production, the creation of an artefact, an investigation, or another means of expression.`}</p>
    <p>{`Once students have decided on a goal (and Global Context) for their project, they are assigned a supervisor from the faculty, who will help them design a timeline, assist them with research, support with choices about presentation, and help with any difficulties that may arise. Process is as important as the final project, and careful record keeping and reflection are vital components of the end product and report.`}</p>
    <p>{`Each student receives access to an online guide that provides a timeline for the project, gives valuable background information, and contains the assessment criteria. The student needs to become very familiar with the support materials, follow the timeline, and meet his / her supervisor regularly to ensure the successful completion of his / her Personal Project.`}</p>
    <p>{`Examples of Personal Projects developed by our students have included:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Researching ‘clean’ fuels and proposing changes to automobile design`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Creating a ‘claymation’ film`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Studying the impact of video games on academic achievement and motivation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Designing and launching a website about the psychology of colour`}</p>
      </li>
    </ul>
    <p>{`Whatever type of project the student chooses to do, however, all projects must include a piece of structured writing in the form of a written report. This must not be longer than 3,500 words.`}</p>
    <p>{`It should be noted that the Personal Project is not part of any course, and is completed outside of classroom time. The role of the supervisor is to guide the student and make sure s/he is on task, not to help the student complete his / her project. Students who do not complete the Personal Project are not eligible for IB MYP Certification or the PSI Honour Roll. Failure to complete the Personal Project will be noted on report cards and official transcripts. Finally, students cannot enter into the IB Diploma Programme at PSI without completion of the Personal Project (This may be waived for students entering the school after the second semester of the Grade 10 year).`}</p>
    <h2 {...{
      "id": "assessment-principles-and-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assessment-principles-and-methods",
        "aria-label": "assessment principles and methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assessment Principles And Methods`}</h2>
    <p>{`Assessment methods in the MYP are many and varied, and may be formal or informal. They include written, oral, group, peer, and self-assessment. In the MYP, teachers make decisions about student achievement using their professional judgment, guided by mandated criteria that are public, known in advance and precise, ensuring that assessment is transparent. Across a variety of assessment tasks (authentic performances of understanding), teachers use descriptors to identify students’ achievement levels against established assessment criteria. `}<em parentName="p">{`MYP: From Principles into Practice`}</em>{` (2014)`}</p>
    <p><strong parentName="p">{`Criterion-Referenced Assessment`}</strong></p>
    <p>{`MYP students are assessed using a set of criteria in each subject area. This is different from the kinds of assessments students may have experienced in other schools. PSI does not use percentages or compare students to each other when assigning grades. Instead, students receive guidelines about what is expected on major assignments; they are marked based on how well they meet those standards. All of these criteria are available on the MyPSI portal.`}</p>
    <p>{`Each subject has four criteria. Throughout the year, teachers assess students many times on each criteria. Teachers do not ‘average’ these criteria marks. Instead, they check to see what is the highest level of sustained effort of each student. At reporting periods, teachers add those highest sustained grades from each criteria and compare it to grade boundaries developed by the IB. This results in a grade based on a 1-7 scale.`}</p>
    <p>{`There is currently no mandatory external examination in MYP. However, students in Grades 9 and 10 do take internal exams each year as one way to prepare for the IB Diploma Programme (DP).`}</p>
    <h3 {...{
      "id": "final-myp-certification-for-year-10-students",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#final-myp-certification-for-year-10-students",
        "aria-label": "final myp certification for year 10 students permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Final MYP Certification for Year 10 Students:`}</h3>
    <p>{`The school holds an annual MYP graduation ceremony and awards certificates of completion to students who:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Earn at least a 3 in all MYP subjects`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Earn at least a 3 for the Personal Project`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Meet the school’s expectations for service and action`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Earn a grade total of at least 36 (out of a possible 63) from the eight subject groups and the Personal Project combined.`}</p>
      </li>
    </ul>
    <p>{`Students who did not meet all of those criteria (e.g., students who do not complete two languages because of their EAL status) receive a certificate for participation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      